import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, Subject, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentService} from '../services/document.service';
import {InitializerService} from '../services/initializer.service';
import { Location } from '@angular/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    isSearch: any;

    constructor(private router: Router,
        private documentService: DocumentService,
        private initializerService: InitializerService,
        private activatedRouter: ActivatedRoute,
        private location: Location) {

        router.events.subscribe((val) => {
            if (this.location.path().includes('/search/') || this.location.path().includes('/hotels/')) {
                this.isSearch = true;
            }
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const urlSearchParams = new URLSearchParams(this.documentService.getWindow()?.location.search);
        const uuidFromSearchParams = (urlSearchParams.get('uuid')) ? urlSearchParams.get('uuid') : null;

        if (this.documentService.getWindow()?.localStorage?.getItem('uuid') === '[object Object]') {
            this.documentService.getWindow()?.localStorage?.removeItem('uuid');
        }

        if (request.url.includes(environment.OTAS_ENDPOINT)) {
            return next.handle(request);
        }

        if (request.url.includes('google.com/recaptcha/api/siteverify')) {
            return next.handle(request);
        }

        if (request.url.includes('datepicker')) {
            return next.handle(request);
        }

        if (request.url.includes('voucher')) {
            return next.handle(request);
        }

        if (uuidFromSearchParams) {
            request = request.clone({
                setHeaders: {
                    'uuid': uuidFromSearchParams
                }
            });
        }

        if (this.documentService.getWindow()?.localStorage?.getItem('uuid')) {

            request = request.clone({
                setHeaders: {
                    'x-hotel-request': `${this.documentService.getWindow()?.sessionStorage?.getItem('person')}`,
                    'x-device': (this.initializerService.isMobileDevice()) ? '1' : '0'
                }
            });

            if (this.isSearch) {
                request = request.clone({
                    setHeaders: {
                        'uuid': `${this.documentService.getWindow()?.localStorage?.getItem('uuid')}`
                    }
                });
            }
        }

        return next.handle(request);
    }

}
